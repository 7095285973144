import React from "react"
import { useDisclosure } from "@chakra-ui/hooks"
import { Collapse } from "@chakra-ui/transition"
import { Box } from "@chakra-ui/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { Text } from "@chakra-ui/layout"
import { Link } from "gatsby"

const Question = ({ item }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box border="1px solid #EDEFF2" m="10px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#fff"
        border="none"
        onClick={onToggle}
        px="25px"
        py="15px"
        cursor="pointer"
        overflow="hidden"
      >
        <Text mr="10px">
          {item.title}&nbsp;
          <Link to={`${item.link}`} className="faq_link">
            {item.linkName}
          </Link>
        </Text>
        {isOpen ? (
          <span>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        ) : (
          <span>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        )}
      </Box>
      <Collapse in={isOpen} animateOpacity>
        <Box color="#000" mt="4" mx="40px" pb="10px" bg="#ffffff">
          {item.description}
        </Box>
      </Collapse>
    </Box>
  )
}

export default Question
