import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Box } from "@chakra-ui/layout"
import { Text } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import Question from "../components/Home/FAQ/Question"
import { Questions } from "../components/Home/FAQ/FaqData"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Navbar from "../components/Utilities/Navbar/Navbar"
import logo from "../images/sensetechLogo.png"
import FooterCommon from "../components/Utilities/FooterCommon/FooterCommon"

const Faq = () => {
  const [aosWidth, setAosWidth] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth < 768) {
        setAosWidth(true)
      } else {
        setAosWidth(false)
      }
    })
  }, [aosWidth])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SenseTech: FAQ</title>
        <meta
          name="description"
          content="SenseTech : App Development || Cloud Support || Migration to Micro Frontend || Cloud Migration support || RPA || Chatbot || E-commerce store"
        />
        <meta
          name="keywords"
          content="mobile app development , web app development , software company , IT solution, IT services"
        />
        <meta name="author" content="SenseTech Innovations Private Limited" />
        <meta property="og:image" content={logo} />
        <link rel="canonical" href="https://thesensetech.com" />
      </Helmet>
      <Navbar />
      <Box id="FAQ" maxW="940px" margin="auto" my="100px" p="15px">
        <Box data-aos="fade-up">
          <Text
            color="#0F2137"
            fontSize="32px"
            textAlign="center"
            fontWeight="700"
          >
            Frequently Ask Question
          </Text>
          <Text color="#858B91" fontSize="16px" textAlign="center">
            Ask your question and meet
          </Text>
        </Box>
        <Box
          mt="60px"
          display="flex"
          alignItems="center"
          flexDirection="column-reverse"
        >
          <Box
            data-aos={!aosWidth ? "fade-right" : "fade-up"}
            w="70%"
            mt="50px"
          >
            <Text color="#0F2137" fontSize="24px" fontWeight="500">
              Do you have any quesiton? Please ask here we ready to support
            </Text>
            <Text color="#343D48" mt="10px" mb="25px">
              If your question is not list here, please feel free to make a
              manual support.
            </Text>
            <Button colorScheme="#02073e" className="btn__primary">
              <AnchorLink to="/contact">Ask your Question</AnchorLink>
            </Button>
          </Box>
          <Box data-aos={!aosWidth ? "fade-left" : "fade-up"} w="80%">
            {Questions.map(item => (
              <Question
                key={item.id}
                item={item}
                title={item.title}
                description={item.description}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <FooterCommon />
    </>
  )
}

export default Faq
