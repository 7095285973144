export const Questions = [
  {
    id: 1,
    title: "1. Explore our services",
    description:
      "We provide a bunch of services. Which includes RPA, Mobile Apps, Web, Cloud Support, CRM, Micro Frontend, Chatbot, E-commerce store setup and many more.",
    link: "/services",
    linkName: "here.",
  },
  {
    id: 2,
    title: "2. How do I create a product with you?",
    description:
      "If you have an idea contact us by our contact us , e-mail , WhatsApp or phone . We'll meet and talk it over. Just be sure to prepare as much info about your idea as possible, it will smoothen the meeting and benefit further cooperation. ",
    link: "/contact",
    linkName: "Contact Us.",
  },
  {
    id: 3,
    title: "3. What do I need to know before contacting you?",
    description:
      "Well, the most important thing to know is what do you want to accomplish. Why do I need this software? What for? What should it do? Having a clear vision in mind is crucial when ordering a software application. You don't want to spend many months developing it with us without being sure what you need.  We'll also be glad to help you get started, building projects from scratch is nothing new for us.",
  },
  {
    id: 4,
    title:
      "4. Do I need to be tech-savvy to cooperate with you and order a software application?",
    description:
      "You certainly do not have to be a software development expert, that's what we're here for :) We welcome your experience in that matter if you happen to have any, but are prepared to guide you through the whole process and explain everything. Focus on your needs and we'll deliver what you require.",
  },
  {
    id: 5,
    title: "5. How much time will it take for you to make my app?",
    description:
      "To give you a precise answer we must prepare a project scope and create a budget. We shall tell you how much of that scope can be completed within the budget and we'll be able to estimate how long will it take after proper analysis.",
  },
  {
    id: 6,
    title: "6. How do you guarantee product quality?",
    description:
      "We test our work (we conduct both development and acceptance tests), we present it to you, we apply your feedback so you know you get what you are paying for.",
  },
  {
    id: 7,
    title:
      "7. I want to be involved in the app development process, do you have a problem with that?",
    description:
      "Not at all! We want you to be sure you'll receive the product you desire. From the planning stage to each finished version, we invite you to evaluate and improve our work. Our philosophy is to work with the client, not merely finish given tasks. We are always ready to listen and communicate.",
  },
  {
    id: 8,
    title: "8. What happens after you finish my app?",
    description:
      "Hopefully the app is exactly what you dreamed of :) But apart from delivering a finished product, we are happy to provide you with technical support and app maintenance should you need it. After all, we know our work inside out. Of course if you want to maintain the app by yourself the source code and all technical data is at your disposal, but even in that case, feel free to contact us if you need any help.",
  },
  {
    id: 9,
    title:
      "9. Can the software be upgraded in the future as new technology becomes available?",
    description:
      "Of course! We are always happy to improve, upgrade and further develop our work.",
  },
  {
    id: 10,
    title: "10. How much do you charge?",
    description:
      "It depends on many things: project scope, time spent on the project and technological issues. We know it sounds vague but it’s simply honest. Remember that each software project is different, especially if you had a particularly original idea. We do not want to give you an unrealistic estimate, instead we want to provide you with the best quality possible within your budget.",
  },
]
