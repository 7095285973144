import { Box, Text } from "@chakra-ui/layout"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import "./FooterCommon.css"

const FooterCommon = () => {
  return (
    <Box bg="var(--footer-color)" pb="10px" color="#ffffff">
      <Box maxW="900px" margin="0 auto" p="15px">
        <Text textAlign="center" fontSize="30px" fontWeight="700">
          Get In Touch
        </Text>
        <Box
          m="16px auto 0 auto"
          fontSize="18px"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridGap="10px"
        >
          <Text fontSize="18px" textAlign="left">
            <a href="tel:+91-9896740648">+91-9896740648</a>
          </Text>
          <Text textAlign="right">158, Prem Nagar, Hisar, Haryana-125001</Text>
        </Box>
        <Box
          m="10px auto 0 auto"
          fontSize="18px"
          className="footerCommon__links"
        >
          <Text textAlign={["center", "left", "left", "left"]} mt="10px">
            <a href="mailto: anamika@thesensetech.com">
              anamika@thesensetech.com
            </a>
          </Text>

          <Box
            display="flex"
            justifyContent={["center", "flex-end", "flex-end", "flex-end"]}
          >
            <Link
              to="https://www.linkedin.com/company/sensetech-innovations/"
              target="_blank"
            >
              <Text
                padding="8px 13px"
                cursor="pointer"
                mr="10px"
                borderRadius="50%"
                bgColor="#0e76a8 "
                className="social__icon"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </Text>
            </Link>
            <Link
              to="https://www.facebook.com/SenseTech-Innovations-102027922056333/"
              target="_blank"
            >
              <Text
                cursor="pointer"
                padding="8px 15.5px"
                borderRadius="50%"
                bgColor="#3b5998"
                className="social__icon"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FooterCommon
